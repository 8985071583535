import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Vector from '../../components/Vector';
import Meter from '../../components/Meter';
// import MeterTopValue from '../../components/MeterTopValue';
// import Accuracy from '../../components/Accuracy';
import ChartPosNegBar from '../../components/ChartPosNegBar';
import { sentimentCoords } from '../../data/testData';
import { useMyContext } from '../../ContextProvider';
import { Timeframe } from '../../types/interfaces';

export interface SentimentProps {
  averageConvictionPercentage: number;
  percentageChange: number;
  positiveConvictionPercentage: number;
  negativeConvictionPercentage: number;
  mixedConvictionPercentage: number;
}

function Sentiment({ title = 'Sentiment' }: { title?: string }) {
  const { timeframeSettings, updateTimeframeSettings } = useMyContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [sentimentData, setSentimentData] = useState<SentimentProps | null>(null);
  const [currentTimeframe, setCurrentTimeframe] = useState<number>(7);

  const getTimestamps = () => {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return {
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
    };
  };

  const fetchTopTokens = async (timeframeValue: number) => {
    setLoading(true);
    setError(null);

    const { currentDate, oneMonthAgoDate } = getTimestamps();

    try {
      const response = await fetch(
        process.env.REACT_APP_USE_REMOTE_API === 'true'
          ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/sentiment-conviction-series?timeframe=${timeframeValue}&startDate=2024-10-01&endDate=${currentDate}`
          : `${process.env.REACT_APP_AWS_API}/api/sentiment-conviction-series?timeframe=${timeframeValue}&startDate=2024-11-01&endDate=${currentDate}`,
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      setSentimentData(data);
    } catch (error) {
      console.error('Error fetching top tokens:', error);
      setError(error instanceof Error ? error.message : String(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopTokens(currentTimeframe);
  }, [currentTimeframe]);

  const handleClick = (timeframe: Timeframe) => {
    let timeframeValue: number;

    switch (timeframe) {
      case 'week':
        timeframeValue = 7;
        break;
      case 'month':
        timeframeValue = 30;
        break;
      case '3month':
        timeframeValue = 90;
        break;
      default:
        timeframeValue = 7;
    }

    setCurrentTimeframe(timeframeValue);
    updateTimeframeSettings(title, timeframe);
  };

  return (
    <div className='general-container'>


      {/* <Accuracy label="Accuracy" value={7}/> */}

      {sentimentData && (
        <>
          <div className="toggle-container">
            <button
              // className={timeframeSettings[title] === 'week' ? 'selected' : ''}
              className={`toggle-button-${timeframeSettings[title] === 'week' ? 'active' : 'inactive'}`}

              onClick={() => handleClick('week')}
            >
              Week
              {timeframeSettings[title] === 'week' && <i className="fa-sharp fa-solid fa-caret-down"></i>}

            </button>
            <button
              className={`toggle-button-${timeframeSettings[title] === 'month' ? 'active' : 'inactive'}`}
              onClick={() => handleClick('month')}
            >
              Month
              {timeframeSettings[title] === 'month' && <i className="fa-sharp fa-solid fa-caret-down"></i>}

            </button>
            <button
              className={`toggle-button-${timeframeSettings[title] === '3month' ? 'active' : 'inactive'}`}
              onClick={() => handleClick('3month')}
            >
              3 Months
              {timeframeSettings[title] === '3month' && <i className="fa-sharp fa-solid fa-caret-down"></i>}

            </button>
          </div>
          <div className="general-data">

            <h1>CURRENT MARKET</h1>

            <Vector
              good={sentimentData.positiveConvictionPercentage}
              bad={sentimentData.negativeConvictionPercentage}
            />
            <Meter
              good={sentimentData.positiveConvictionPercentage}
              bad={sentimentData.negativeConvictionPercentage}
            />

            {/* <div className="general-data"> */}
            <h1>HISTORICAL SENTIMENT</h1>
            <ChartPosNegBar sentimentCoords={sentimentCoords} />
            {/* </div> */}

            <h1>AVERAGE CONVICTION PERCENTAGE</h1>
            
            <div className='pretty-number-hero'>
              <h1>{sentimentData && `${sentimentData.averageConvictionPercentage.toFixed(0)}%`}
                {/* <MeterTopValue 
                    good={sentimentData.positiveConvictionPercentage}
                    neutral={sentimentData.mixedConvictionPercentage}
                    bad={sentimentData.negativeConvictionPercentage}
                    title="" /> */}
              </h1>
            </div>

          </div>
        </>
      )}



    </div>
  );
}

export default Sentiment;