import { useState, useEffect, useMemo, useCallback } from 'react';
import Typewriter from "typewriter-effect";
import CountUp from 'react-countup';
import CoinDay from '../CoinDay';
export interface ChatterProps {
  averageConvictionPercentage: number;
  percentageChange: number;
  positiveConvictionPercentage: number;
  negativeConvictionPercentage: number;
  mixedConvictionPercentage: number;
  cryptoProjects: string[];
  projectName: string;
  tokenSymbol: string;
  chainName: string;
  logo: string;
  total_mentions: number;
  total_weighted_view_score: number;
  channelCount: number;
}

export interface PendingProps {
  videoId: string;
  channelName: string;
  videoLink: string;
  title: string;
  views: number;
  cryptoProjects: string[];
}

export interface ServerStatsProps {
  channelName: string;
  dailyVideoSeries: [
    {
      date: string;
      count: number
    }
  ],
  cronJobStatus: {
    videoScanner: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      scannerRunning: false;
    },
    githubPush: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      pushRunning: boolean;
    },
    tokenPopulate: {
      isRunning: boolean;
      isExecuting: boolean;
      schedulerExists: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      currentInterval: number;
      isInitialized: boolean;
    }
  },
  videoStats: {
    latestPendingVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestScannedVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
      status: string;
    };
    totalVideosAllTime: number;
    totalVideosInTimespan: number;
    uniqueChannelsInTimespan: number;
  },
  tokenStats: {
    latestCalls: [
      {
        tokenId: string,
        videoId: string,
        datetime: string,
        projectName: string,
        tokenSymbol: string
      }],
    latestSentiment: {
      videoId: string,
      datetime: string,
      market_sentiment: string,
      conviction: number
    }
  };
}

function Chatter({ title = 'Chatter' }: { title?: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [serverStatusData, setServerStatusData] = useState<ServerStatsProps | null>(null);

  const getTimestamps = useCallback(() => {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return {
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
    };
  }, []);

  const fetchTopTokens = useCallback(async () => {
    setError(false);
    const { currentDate, oneMonthAgoDate } = getTimestamps();

    try {
      setCounter(false);
      const responseServerStatus = await fetch(
        process.env.REACT_APP_USE_REMOTE_API === 'true'
          ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/scanner-status?startDate=${oneMonthAgoDate}&endDate=${currentDate}`
          : `${process.env.REACT_APP_AWS_API}/api/scanner-status?startDate=${oneMonthAgoDate}&endDate=${currentDate}`,
      );

      if (!responseServerStatus.ok) {
        throw new Error(`HTTP error! status: ${responseServerStatus.status}`);
      }

      const dataServerStatus: ServerStatsProps = await responseServerStatus.json();
      setServerStatusData(dataServerStatus);
      setLoading(false);
      setCounter(true);
    } catch (error) {
      console.error('Error fetching SERVER STATUS:', error);
      setError(true);
    }
  }, [getTimestamps]);

  useEffect(() => {
    fetchTopTokens();
    const interval = setInterval(fetchTopTokens, 30000);
    return () => clearInterval(interval);
  }, [fetchTopTokens]);

  const latestPendingVideo = useMemo(() => serverStatusData?.videoStats.latestPendingVideo, [serverStatusData]);
  const latestScannedVideo = useMemo(() => serverStatusData?.videoStats.latestScannedVideo, [serverStatusData]);
  const dailyVideoSeriesCount = useMemo(() => serverStatusData?.dailyVideoSeries[0]?.count, [serverStatusData]);
  const totalVideosAllTime = useMemo(() => serverStatusData?.videoStats.totalVideosAllTime, [serverStatusData]);

  if (!serverStatusData) {
    return null;
  }

  return (
    <>
      <div className="general-container">
        <div className="general-data-activity">
          {loading ? (
            <div className="loading">
              <i className="fa-sharp-duotone fa-solid fa-spinner-third"></i>
            </div>
          ) : (

            <div className='activity-status-container'>

              {/* COLUMN 1 */}

              <div className='activity-status-feed-standard'>

              <div className='activity-status-feed'>
                

                <CoinDay />

                </div>


              </div>

              {/* COLUMN 2 */}

              <div className='activity-status-feed-standard'>

                <div className='activity-status-rollup'>

                  <div className='activity-status-item'>
                    <span className='activity-status-item-text'>SOURCES SCANNED<br /><strong>TODAY</strong> </span>
                    <CountUp
                      start={0}
                      end={dailyVideoSeriesCount || 0}
                      duration={1}
                      useEasing={true}
                      delay={0}
                      decimals={0}
                      className="activity-status-item-count"
                    />
                  </div>
                  <div className='activity-status-item'>
                    <span className='activity-status-item-text'>SOURCES SCANNED<br /><strong>ALL TIME</strong></span>
                    <CountUp
                      start={0}
                      end={totalVideosAllTime || 0}
                      duration={.72}
                      useEasing={true}
                      delay={0}
                      decimals={0}
                      className="activity-status-item-count"
                    />
                  </div>

                </div>
              </div>

              {/* COLUMN 3 */}

              <div className='activity-status-feed-empty'>
                <div className='activity-status-feed'>
                  <Typewriter
                    options={{
                      autoStart: true,
                      loop: true,
                      cursor: "",
                      delay: 0,
                      deleteSpeed: 0
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString(`<h1>CURRENTLY SCANNING</h1>`)
                        .pauseFor(360)
                        .typeString(`<h2>${latestPendingVideo?.title}</h2>`)
                        .pauseFor(360)
                        .typeString(`<a href='https://www.youtube.com/@${latestPendingVideo?.channelName}' target='_blank' rel='noopener noreferrer'><i class='fa-brands fa-youtube'></i> ${latestPendingVideo?.channelName}</a>`)
                        .pauseFor(9000)
                        .deleteAll(0)
                        .pauseFor(1200)
                        .typeString(`<h1>LATEST COMPLETED SCAN</h1>`)
                        .pauseFor(360)
                        .typeString(`<h2>${latestScannedVideo?.title}</h2>`)
                        .pauseFor(360)
                        .typeString(`<a href='https://www.youtube.com/@${latestScannedVideo?.channelName}' target='_blank' rel='noopener noreferrer'><i class='fa-brands fa-youtube'></i> ${latestScannedVideo?.channelName}</a>`)
                        .pauseFor(9000)
                        .deleteAll(0)
                        .start();
                    }}
                  />
                </div>

                <div className="scanner-status-counter">
                  <span>
                    {counter ? (
                      <>
                        API HEALTH CHECK IN
                        <strong>
                          <CountUp
                            start={30}
                            end={0}
                            duration={30}
                            useEasing={false}
                            delay={0}
                            decimals={1}
                          />
                        </strong>
                        SECONDS
                      </>
                    ) : "UPDATING"}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Chatter;