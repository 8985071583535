import { useState, useEffect } from 'react';
import Typewriter from "typewriter-effect";
import CountUp from 'react-countup';

export interface TickerProps {
  averageConvictionPercentage: number;
  percentageChange: number;
  positiveConvictionPercentage: number;
  negativeConvictionPercentage: number;
  mixedConvictionPercentage: number;
  cryptoProjects: string[];
  projectName: string;
  tokenSymbol: string;
  chainName: string;
  logo: string;
  total_mentions: number;
  total_weighted_view_score: number;
  channelCount: number;
}

export interface PendingProps {
  videoId: string;
  channelName: string;
  videoLink: string;
  title: string;
  views: number;
  cryptoProjects: string[];
}

export interface ServerStatsProps {
  channelName: string;
  dailyVideoSeries: [
    {
      date: string;
      count: number
    }
  ],
  cronJobStatus: {
    videoScanner: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      scannerRunning: false;
    },
    githubPush: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      pushRunning: boolean;
    },
    tokenPopulate: {
      isRunning: boolean;
      isExecuting: boolean;
      schedulerExists: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      currentInterval: number;
      isInitialized: boolean;
    }
  },
  videoStats: {
    latestPendingVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestScannedVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
      status: string;
    };
    totalVideosAllTime: number;
    totalVideosInTimespan: number;
    uniqueChannelsInTimespan: number;
  },
  tokenStats: {
    latestCalls: [
      {
        tokenId: string,
        videoId: string,
        datetime: string,
        projectName: string,
        tokenSymbol: string
      }],
    latestSentiment: {
      videoId: string,
      datetime: string,
      market_sentiment: string,
      conviction: number
    }
  };
}

function Ticker({ title = 'Ticker' }: { title?: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [sentimentData, setSentimentData] = useState<TickerProps[] | null>(null);
  const [serverStatusData, setServerStatusData] = useState<ServerStatsProps | null>(null);
  const [count, setCount] = useState(0);

  const getTimestamps = () => {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return {
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
    };

  };

  useEffect(() => {
    // Initial fetch
    fetchTopTokens();

    // Set up interval for every 30 secs (30000 milliseconds)
    const interval = setInterval(() => {
      fetchTopTokens();


    }, 30000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array since we want this to run once on mount

  // Move fetchTopTokens outside of the useEffect for reusability
  const fetchTopTokens = async () => {
    setError(false);

    const { currentDate, oneMonthAgoDate } = getTimestamps();

    try {
      const response = await fetch(
        process.env.REACT_APP_USE_REMOTE_API === 'true'
          ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}&category=meme`
          : `${process.env.REACT_APP_AWS_API}/api/top-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}&meme`,
      );
      setCounter(true);


      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data: TickerProps[] = await response.json();
      setSentimentData(data);
    } catch (error) {
      console.error('Error fetching top tokens:', error);
      setError(true);
    } finally {
      setLoading(false);
    }

    // try {
    //   const responsePending = await fetch(
    //     process.env.REACT_APP_USE_REMOTE_API === 'true'
    //       ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/pending-videos-crypto-projects`
    //       : `${process.env.REACT_APP_AWS_API}/api/pending-videos-crypto-projects`,
    //   );

    //   if (!responsePending.ok) {
    //     throw new Error(`HTTP error! status: ${responsePending.status}`);
    //   }
    //   const dataPending: PendingProps[] = await responsePending.json();
    //   setTopViews(dataPending);
    // } catch (error) {
    //   console.error('Error fetching top tokens:', error);
    // } finally {
    //   setLoadingPending(false);
    // }

    try {
      setCounter(false);

      const responseServerStatus = await fetch(
        process.env.REACT_APP_USE_REMOTE_API === 'true'
          ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/scanner-status?startDate=${oneMonthAgoDate}&endDate=${currentDate}`
          : `${process.env.REACT_APP_AWS_API}/api/scanner-status?startDate=${oneMonthAgoDate}&endDate=${currentDate}`,
      );

      if (!responseServerStatus.ok) {
        throw new Error(`HTTP error! status: ${responseServerStatus.status}`);
      }
      const dataServerStatus: ServerStatsProps = await responseServerStatus.json();
      setServerStatusData(dataServerStatus);
      setLoading(false);
      setCounter(true);



    } catch (error) {
      console.error('Error fetching SERVER STATUS:', error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (sentimentData && prevCount < sentimentData.length - 1) {
          return prevCount + 1;
        } else {
          return 0; // Reset to the first item
        }
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [sentimentData]);

  useEffect(() => {
    // Reset the class to trigger re-animation
    const timeout = setTimeout(() => {
    }, 50); // Small delay to allow the class to be removed and re-added

    return () => clearTimeout(timeout);
  }, [count]);

  // Helper function to replace '/thumb/' with '/standard/'
  const replaceThumbWithStandard = (url: string): string => {
    return url.replace('/thumb/', '/standard/');
  };


  if (!serverStatusData) {
    return null;
  }

  return (
    <>

      <div className="nav-ticker-container">
      {/* <div className="general-container" style={{ width: '500px', padding: '0', margin: '0 0 24px 0' }}> */}


          <div className="chatter-ticker-wrap">
            <div className="chatter-ticker">
              {sentimentData && sentimentData.map((item, index) => (
                <div key={`original-${index}`} className="chatter-ticker-item">
                  <img id="logo" src={replaceThumbWithStandard(item.logo)} alt="Logo" />
                  <h1>{item.projectName}<span>{item.tokenSymbol}</span></h1>
                </div>
              ))}
              {sentimentData && sentimentData.map((item, index) => (
                <div key={`duplicate-${index}`} className="chatter-ticker-item">
                  <img id="logo" src={replaceThumbWithStandard(item.logo)} alt="Logo" />
                  <h1>{item.projectName}<span>{item.tokenSymbol}</span></h1>
                </div>
              ))}
            </div>
          </div>

      </div>

    </>
  );
}

export default Ticker;