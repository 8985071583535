import React from 'react';
import {
  Sparklines,
  SparklinesLine,
  // SparklinesReferenceLine,
} from 'react-sparklines';


interface ChartLineProps {
  data: number[];
  stroke: string;
  strokeWidth: number;
}

const ChartLine: React.FC<ChartLineProps> = ({ data, stroke, strokeWidth }) => {
  return (

    <Sparklines
      data={data}
      style={{
        background: 'transparent',
        height: '24px',
        width: '100%',
      }}
    >
      <SparklinesLine
        style={{
          strokeWidth: strokeWidth,
          stroke: stroke,
          fill: 'none',
        }}
      />

      {/* <SparklinesReferenceLine
              type="mean"
              style={{
                stroke: "#ffffff",
                strokeOpacity: .72,
                strokeWidth: 1,
                strokeDasharray: "3, 1",
              }}
            /> */}
    </Sparklines>
  );
};

export default ChartLine;
