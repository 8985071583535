import React from 'react';
import CountUp from 'react-countup';

interface PrettyNumberProps {
  value: number;
  duration?: number;
  disableMoreSuffix?: boolean;
  hero?: boolean;  // Add hero prop definition

}

const countUpMin = 20;
const formatValue = (value: number, disableMoreSuffix: boolean, hero: boolean): { number: number; suffix: string } => {
  if (value >= 1000000) {
    const millions = Math.floor(value / 1000000);
    return { number: millions, suffix: disableMoreSuffix ? 'M' : 'M+' };
  } else if (value >= 10000) {
    const thousands = Math.floor(value / 1000);
    return { number: thousands, suffix: disableMoreSuffix ? 'k' : 'k+' };
  // } else if (value >= 1000) {
  //   return { number: 1, suffix: disableMoreSuffix ? 'k' : 'k+' };
  } else {
    return { number: value, suffix: '' };
  }
};

const PrettyNumber: React.FC<PrettyNumberProps> = ({ value, duration, disableMoreSuffix, hero }) => {
  const { number, suffix } = formatValue(value, !!disableMoreSuffix, !!hero);

  return (
    <span className={hero ? 'pretty-number-hero' : 'pretty-number'}>
      {number < countUpMin ? (
        <span className="fade-in">
          <span>{number}</span>
          <span className="suffix">{suffix}</span>
        </span>
      ) : (
        <>
          <CountUp start={0} end={number} duration={duration || 0.5} useEasing={true} delay={0} decimals={0} />
          <span className="suffix">{suffix}</span>
        </>
      )}
    </span>
  );
};

export default PrettyNumber;
