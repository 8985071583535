import React from 'react';
// import CoinsTop from '../../modules/CoinsTop';
// import CoinsTopNew from '../../modules/CoinsTopNew';
// import ChainsTop from '../../modules/ChainsTop';
// import ChannelsTop from '../../modules/ChannelsTop';
// import CoinsNew from '../../modules/CoinsNew';
// import Promotion from '../../modules/Promotion';
// import PromotionReset from '../../modules/PromotionReset';
// import ViewsTrends from '../../modules/ViewsTrends';
// import CategoriesTop from '../../modules/CategoriesTop';
// import DotMatrixGraph from '../../modules/Matrix'
// import Buzzwords from '../../modules/Buzzwords';
// import SponsorAd from '../../modules/SponsorAd';
import Versus from '../../modules/Versus';
import CoinsTopDefault from '../../modules/CoinsTopDefault';
import Sentiment from '../../modules/Sentiment';
import Chatter from '../../modules/Chatter';
import Calls from '../../modules/Calls';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Nav from '../../components/Nav';
import { useMyContext } from '../../ContextProvider';

// const promotionStatus = localStorage.getItem('promotionStatus');

const Core: React.FC = () => {
  const { moduleBuzzwords, compactStatus } = useMyContext();

  // New state for slider value
  // const [sliderValue, setSliderValue] = useState(0);

  // Function to calculate background color based on slider value
  // const calculateColor = (value: number) => {
  //   const colors = ['#000d21', '#121d2d', '#135A90' , '#7473B3', '#50b8e7', '#99aebb', 'white'];
  //   return colors[value];
  // };

  // Effect to update body background color when slider value changes
  // useEffect(() => {
  //   document.body.style.backgroundColor = calculateColor(sliderValue);
  // }, [sliderValue]);

  return (
    <>
      {/*  slider input for changing background color */}
      {/* <input
            type="range"
            min="0"
            max="8"
            value={sliderValue}
            onChange={(e) => setSliderValue(Number(e.target.value))}
            style={{ position: 'fixed', top: '12px', right: '240px', zIndex: 900000 }}
          /> */}
      <Nav value="" />

      <div className="masonry">

        <Chatter />
        <CoinsTopDefault />

        <ResponsiveMasonry columnsCountBreakPoints={{ 360: 1, 720: 1, 960: 2, 1024: 2, 1280: 3, 1440: 3, 1600: 3, 1800: 3 }}>
       
       
        <Masonry columnsCount={3}>
        <Calls />
        <Sentiment />
        <Versus />

        </Masonry> 
        </ResponsiveMasonry>
        {/* 
        <SponsorAd />
        {/* <Buzzwords />  */}
        {/* <ViewsTrends /> */}

        {/* <DotMatrixGraph /> */}
        {/* 
        
            <CategoriesTop premium={true} />
         

            <ViewsTrends />
            <CoinsNew />
            <CoinsTop />
            {!promotionStatus ? <Promotion /> : <PromotionReset />}
            <ChainsTop />
            <ChannelsTop />
            <Buzzwords />  */}

      </div>
    </>
  );
};

export default Core;