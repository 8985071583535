import React from 'react';
import PrettyNumber from '../../components/PrettyNumber';
import NumberChange from '../../components/NumberChange';

interface RollupMicroProps {
  label: string;
  value: number;
  isPercentage?: boolean;
}

const RollupMicro: React.FC<RollupMicroProps> = ({ label, value, isPercentage }) => {
  return (
    <div className="rollup-micro">
      {!!isPercentage ? (
          <NumberChange value={value} />
      ) : (
          <PrettyNumber value={value} hero={false}/>
      )}
      <small>{label}</small>
    </div>
  );
};

export default RollupMicro;
