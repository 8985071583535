import { useState, useEffect } from 'react';


export interface ServerStatsProps {
  channelName: string;
  dailyVideoSeries: [
    {
      date: string;
      count: number
    }
  ],
  cronJobStatus: {
    videoScanner: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      scannerRunning: false;
    },
    githubPush: {
      isRunning: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      pushRunning: boolean;
    },
    tokenPopulate: {
      isRunning: boolean;
      isExecuting: boolean;
      schedulerExists: boolean;
      lastRunTime: string;
      totalRuns: number;
      nextScheduledRun: string;
      currentInterval: number;
      isInitialized: boolean;
    }
  },
  videoStats: {
    latestPendingVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestScannedVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
    };
    latestVideo: {
      videoId: string;
      channelName: string;
      title: string;
      timestamp: number;
      status: string;
    };
    totalVideosAllTime: number;
    totalVideosInTimespan: number;
    uniqueChannelsInTimespan: number;
  },
  tokenStats: {
    latestCalls: [
      {
        tokenId: string,
        videoId: string,
        datetime: string,
        projectName: string,
        tokenSymbol: string
      }],
    latestSentiment: {
      videoId: string,
      datetime: string,
      market_sentiment: string,
      conviction: number
    }
  };
}

function Calls({ title = 'Calls' }: { title?: string }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [callsData, setCallsData] = useState<ServerStatsProps | null>(null);


  const getTimestamps = () => {
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    return {
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
    };

  };

  useEffect(() => {
    fetchTopTokens();
  }, []);

  const fetchTopTokens = async () => {
    setLoading(true);
    setError(false);

    const { currentDate, oneMonthAgoDate } = getTimestamps();

    try {
      const response = await fetch(
        process.env.REACT_APP_USE_REMOTE_API === 'true'
          ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/scanner-status?startDate=${oneMonthAgoDate}&endDate=${currentDate}`
          : `${process.env.REACT_APP_AWS_API}/api/scanner-status?startDate=${oneMonthAgoDate}&endDate=${currentDate}`,
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const dataServerStatus: ServerStatsProps = await response.json(); // Change to single object
      setCallsData(dataServerStatus);
    } catch (error) {
      console.error('Error fetching top tokens:', error);
      setError(true);
    } finally {
      setLoading(false);
    }


  };

  const getRelativeTimeString = (datetime: string): string => {
    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
    const now = new Date();
    const past = new Date(datetime + 'Z');
    const diffInMilliseconds = now.getTime() - past.getTime();
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    const diffInDays = diffInHours / 24;

    if (diffInHours < 24) {
      return rtf.format(-Math.floor(diffInHours), 'hour');
    } else {
      return rtf.format(-Math.floor(diffInDays), 'day');
    }
  };


  if (!callsData) {
    return null;
  }


  return (
    <>
      <div className='general-container'>
        <div className="general-data-list">
        <h1>LATEST CALLS</h1>
        
          {callsData && callsData.tokenStats.latestCalls.map((item, index) => (
            <a href={`https://www.youtube.com/watch?v=${item.videoId}`} target="_blank" className='capsule' key={index}>
              {item.projectName}
              <small>
                {getRelativeTimeString(item.datetime)}
              </small>
            </a>
          ))}
        </div>
      </div>
    </>
  );
}

export default Calls;