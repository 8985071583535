import React, { useState } from 'react';
// import Offer from '../../components/Offer';
import { useMyContext } from '../../ContextProvider';
import Typewriter from "typewriter-effect";
import Ticker from '../../modules/Ticker';

interface NavProps {
  value: string;
}

const Nav: React.FC<NavProps> = ({ value }) => {
  // const { moduleBuzzwords, toggleModule } = useMyContext();

  // const [state, setState] = useState({
  //   top: false,
  //   left: false,
  //   bottom: false,
  //   right: false,
  // });

  // function handleBuzzwords() {
  //   toggleModule('moduleBuzzwords');
  //   localStorage.setItem('moduleBuzzwords', String(!moduleBuzzwords));
  // }

  // type Anchor = 'top' | 'left' | 'bottom' | 'right';

  // const toggleDrawer = (anchor: Anchor, newOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
  //   if (
  //     event.type === 'keydown' &&
  //     ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
  //   ) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: newOpen });
  // };

  return (
    <>
      <div className="nav">

      <Ticker />

        <div className="nav-left">
         
          <Typewriter
            options={{
              autoStart: true,
              loop: false,
              cursor: "",
              delay: 36,
            }}
            onInit={(typewriter) => {
              typewriter
                .pauseFor(600)
                .typeString("<span>FUDATLAS <strong>BETA</strong></span>")                
                .pauseFor(1200)
                .typeString("<br/><small>STAY AHEAD OF THE HERD</small>")
                .start();
            }}

          />


        </div>
        <div className="nav-right">
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
              cursor: "_",
              delay: 0,
              deleteSpeed: 0
            }}
            onInit={(typewriter) => {
              typewriter
                .pauseFor(3600)
                .typeString("<small>DEV NOTE</small><br/>")
                .typeString(
                  `${process.env.REACT_APP_USE_REMOTE_API === 'true'
                    ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}`
                    : process.env.REACT_APP_AWS_API
                  }`
                )
                .pauseFor(6000)
                .deleteAll(0)
                .typeString('check out our TELEGRAM<br/><a href=https://t.me/fudatlasbot target=_blank>@fudatlasbot</a>')
                .pauseFor(6000)
                .deleteAll(0)
                .start();
            }}

          />

{/* 
          {!moduleBuzzwords && (
            <button onClick={handleBuzzwords} className="module-icon">
              <i className="fa-sharp fa-solid fa-grid-2-plus"></i>
            </button>
          )} */}
        </div>
      </div>

    </>
  );
};

export default Nav;
