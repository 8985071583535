import React from 'react';
import Spark from '../ChartLine';
import RollupMicro from '../RollupMicro';
import CustomTooltip from '../CustomTooltip';
import { ListProps } from '../../types/interfaces';


const List: React.FC<ListProps> = ({
  title,
  secondTitle,
  thirdTitle,
  categories,
  tooltipValue,
  logo,
  logoClass,
  stats,
  spark,
  selected,
  onClick,
}) => {

  const fallbackData = [
    0,0 
  ];

  const sparkData = spark && spark.length > 1 ? spark : fallbackData;
  
  return (
    <>
    <li onClick={onClick} className={`${logo || logoClass ? 'logo' : ''} ${selected ? 'selected' : ''}`}>
     { selected ?  <i className='fa-sharp fa-solid fa-caret-left'></i> :  <span></span> }
      {logo && logo !== '' ? (
        <img id="logo" className="logo" src={logo} alt="" />
      ) : logoClass && logoClass !== '' ? (
        <i id="logo" className={`logo fa-solid ${logoClass}`}></i>
      ) : null}

      <CustomTooltip value={tooltipValue ? tooltipValue : title}>
        <h4>
          {title.replace(/\S+/g, function (word) {
            if (word.length > 4) {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            } else {
              return word.charAt(0).toUpperCase() + word.slice(1);
            }
          })}
          &nbsp;<span>{secondTitle}</span>
          <small>{thirdTitle}</small>
        </h4>
      </CustomTooltip>

      {stats.map((item, index) => (
        <RollupMicro key={index} label={item.label} value={item.value} isPercentage={!!item.isPercentage} />
      ))}

      {sparkData && <Spark data={sparkData} strokeWidth={3} stroke={'#50b8e7'} />}

      
    </li>
      <span className="list-module-border"></span>
    </>

  );
};

export default List;
