import React, { useState, useEffect } from 'react';
import Details from '../../components/Details';
import Title from '../../components/Title';
import Price from '../../components/Price';
import Rollup from '../../components/Rollup';
import { mentionsCoords } from '../../data/testData';
import { ListProps } from '../../types/interfaces';
import List from '../../components/List';
import PrettyNumber from '../../components/PrettyNumber';

function CoinDay() {

  const [correlationPercentage, setCorrelationPercentage] = useState<number | undefined>(undefined);
  const [sumOfY, setSumOfY] = useState(0);
  const latestPrice = mentionsCoords.length > 0 ? mentionsCoords[mentionsCoords.length - 1].y2 : 0;
  const [topTokens, setTopTokens] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);



  const getTimestamps = () => {
    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const currentTimestamp = Math.floor(Date.now() / 1000);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    
    const threeDaysAgo = new Date();
    threeDaysAgo.setHours(threeDaysAgo.getHours() - 72);
 
    const oneMonthAgoTimestamp = Math.floor(oneMonthAgo.getTime() / 1000);
    const threeDaysAgoTimestamp = Math.floor(threeDaysAgo.getTime() / 1000);

    return {
      currentTimestamp,
      oneMonthAgoTimestamp,
      threeDaysAgoTimestamp,
      currentDate: formatDate(new Date()),
      oneMonthAgoDate: formatDate(oneMonthAgo),
      threeDaysAgoDate: formatDate(threeDaysAgo),
    };
  };



  useEffect(() => {


    const fetchTopTokens = async () => {
      setLoading(true);
      setError(null);

      const { currentDate, oneMonthAgoDate, threeDaysAgoDate } = getTimestamps();

      try {

        const response = await fetch(
          process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-new-tokens?interval=daily&start_date=${threeDaysAgoDate}&end_date=${currentDate}`
            : `${process.env.REACT_APP_AWS_API}/api/top-new-tokens?interval=daily&start_date=${threeDaysAgoDate}&end_date=${currentDate}`,
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTopTokens(data);
      } catch (error) {
        console.error('Error fetching tokens:', error);
        if (error instanceof Error) {
          setError(error.message);
        } else {
          setError(String(error));
        }
      } finally {
        setLoading(false);
      }
    };

    fetchTopTokens();
  }, []);


  if (!topTokens) {
    return null;
  }

  // Sort tokens by views and get the most mentioned token
  const mostViewsToken = [...topTokens].sort((a, b) => b.total_views - a.total_views)[0];
  
  return (
    <>
        <h1>TODAY'S MOST DISCUSSED COIN</h1>
       
          <div className="feature-stat">
            <div className="stat-container">
              <div className="stat-container-left">
                <Title
                  label={'Coin'}
                  effect={true}
                  title={mostViewsToken.id}
                  symbol={mostViewsToken.tokenSymbol}
                  subtitle={mostViewsToken.chainName}
                  logo={mostViewsToken.logo}
                  hero={false}
                />
              </div>
              <div className="stat-container-right">
                <div className="stat-right-hero">
                    <PrettyNumber value={mostViewsToken.total_views} hero={true} />
                    <small className="subtitle">MENTIONS</small>
                  </div>
                {<Price label={'Current Coin Price'} price={mostViewsToken.currentPrice} currency={'USD'} />}
              </div>
            </div>
          </div>
    </>
  );
}

export default CoinDay;
