
import React, { useState, useEffect, useRef, useMemo } from 'react';
import CountUp from "react-countup";

interface VectorProps {
    good: number;
    bad: number;
}

function calculatePercentages(good: number, bad: number): { goodPercentage: number, badPercentage: number } {

    const total = good + bad;
    const goodPercentage = (good / total) * 100;
    const badPercentage = (bad / total) * 100;

    return { goodPercentage, badPercentage };
}

const Vector: React.FC<VectorProps> = ({ good, bad }) => {
    const { goodPercentage, badPercentage } = calculatePercentages(good, bad);
    const maxPercent = Math.round(Math.max(goodPercentage, badPercentage));
    const calcTotal = good  + bad;
    const maxCount = Math.max(good, bad);
    const calcVector = maxCount / calcTotal * 100;


    let largestCount: 'positive' | 'negative';
    let message = '';
    let image = '';

    if (maxPercent === Math.round(goodPercentage)) {
        largestCount = 'positive';
        message = 'BULLISH';
        image = 'img/bull-reverse.png';
    } else {
        largestCount = 'negative';
        message = 'BEARISH';
        image = 'img/bear-reverse.png';
    }

    //DEV NOTE: Vector percentage is calculated by dividing largest count of positive, neutral or negative sentiments, in this case ' + largestCount + ' with a count of ' + maxCount + ' and then dividing by total sentiment of ' + calcTotal;

    const [rotationAngle, setRotationAngle] = useState<number>(0);
    const [triggerCountUp, setTriggerCountUp] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const getRotationAngle = useMemo(() => {
        return function calculateRotationAngle(calcVector: number) {

            if (largestCount === 'positive' && calcVector > 75) {
                return -6;
            } else if (largestCount === 'positive' && calcVector > 0 && calcVector < 75) {
                return -2;
            } else if (largestCount === 'negative' && calcVector > 0 && calcVector < 75) {
                return 3;
            } else if (largestCount === 'negative' && calcVector > 75) {
                return 6;
            } else {
                return 0;
            }
       

        };
    }, [largestCount]);

    useEffect(() => {
        const newRotationAngle = getRotationAngle(calcVector);
        setRotationAngle(newRotationAngle);

        timeoutRef.current = setTimeout(() => {
            setTriggerCountUp(true);
        }, 720);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [calcVector, getRotationAngle]);


    const vectorRotation = { transform: `rotate(${rotationAngle}deg) scaleX(100%)` };

    return (
        <>

            <div className='vector'>
                
                {calcVector > 40 &&
                    <div className="vector-container" style={vectorRotation}>

                        <div className={`vector-bar-${largestCount} grow-right`}>

                            {triggerCountUp &&
                                <>

                                    <h2>{largestCount === 'positive' && <img src={image} alt="" />}{largestCount === 'negative' && <img src={image} alt="" />}</h2>
                                    {/* <CountUp
                                    start={0}
                                    end={calcVector}
                                    duration={.4}
                                    useEasing={false}
                                    delay={0}
                                    decimals={0}
                                /> */}
                                    <span>{message}</span>
                                </>
                            }

                        </div>
                    </div>
                }


            </div>

        </>

    );

};

export default Vector;
