import React from 'react';
import CountUp from 'react-countup';

interface PriceProps {
  label: string;
  price: number;
  currency: string;
}

const Price: React.FC<PriceProps> = ({ label, price, currency }) => {
  const formatPrice = (price: number): string => {
    if (price === undefined || price === null) {
      return '0';
    }
    
    if (price >= 1000) {
      return Math.round(price).toLocaleString();
    } else if (price >= 1) {
      return price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
      return price.toLocaleString(undefined, { 
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 3
      });
    }
  };

  const formattedPrice = formatPrice(price);

  return (
    <div className="stat-right-hero">

      <span className="number">
        <span className='pretty-number-hero'>
        <span className='prefix fade-delay-long'>$</span>
        <CountUp
        start={0}
        end={parseFloat(formattedPrice)}
        duration={0.6}
        useEasing={true}
        delay={0.3}
        decimals={2}
      />
        <span className='suffix fade-delay-long'>{currency}</span></span>
      </span>
      <small className="subtitle">{label}</small>

    </div>
  );
};

export default Price;
