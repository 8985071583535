import React, { useState, useEffect, useMemo } from 'react';
import FeatureWithInteractiveList from '../../components/FeatureWithInteractiveList';
import List from '../../components/List';
import ChartDetailedInFull from '../../components/ChartDetailedInFull';
import { noLogoPath } from '../../config';
import { ListProps } from '../../types/interfaces';

type ViewMode = 'top' | 'recent' | 'emerging';

function CoinsTopDefault({ premium = true }: { premium?: boolean }) {
    const [selectedCoin, setSelectedCoin] = useState<ListProps | null>(null);
    const [topTokens, setTopTokens] = useState<any[] | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [viewMode, setViewMode] = useState<ViewMode>('top');

    const convertToCoordinates = (
        data: Array<{ interval_start: string; weighted_view_score: number }>,
    ): Array<{ x: number; y: number }> => {
        if (!data || data.length === 0) return [];

        const sortedData = data.sort((a, b) => new Date(a.interval_start).getTime() - new Date(b.interval_start).getTime());
        const startDate = new Date(sortedData[0].interval_start);
        const endDate = new Date(sortedData[sortedData.length - 1].interval_start);

        const result: Array<{ x: number; y: number }> = [];
        let currentDate = new Date(startDate);



        while (currentDate <= endDate) {
            const timestamp = Math.floor(currentDate.getTime() / 1000);
            const dataPoint = sortedData.find((item) => new Date(item.interval_start).toDateString() === currentDate.toDateString());

            result.push({
                x: timestamp,
                y: dataPoint ? dataPoint.weighted_view_score : 0,
            });

            currentDate.setDate(currentDate.getDate() + 1);
        }

        return result;
    };

    const createListItem = (coin: any, onClick?: () => void): ListProps => {
        const processedLogo = coin.logo ? coin.logo.replace('/thumb/', '/standard/') : coin.logo;

        return {
            label: 'Coin',
            title: coin.projectName,
            chainName: coin.chainName,
            secondTitle: coin.tokenSymbol,
            thirdTitle: coin.chainName || `${coin.projectName}`,
            categories: coin.Category,
            price: coin.currentPrice,
            percentChange: coin.percentChange,
            logo: processedLogo,
            stats: [
                { label: 'views', value: coin.total_weighted_view_score },
                { label: 'change', value: coin.percentChange, isPercentage: true },
                { label: 'mentions', value: coin.total_mentions },
                { label: 'channels', value: 0 },
            ],
            spark: [6, 12, 73, 8, 12, 33],
            coords: coin.timeSeriesData ? convertToCoordinates(coin.timeSeriesData) : [],
            onClick: onClick || (() => { }),
        };
    };

    const listItems: ListProps[] = useMemo(
        () =>
            topTokens?.slice(0, 8).map((coin: any) => {
                const listItem = createListItem(coin);
                return {
                    ...listItem,
                    onClick: () => {
                        setSelectedCoin(listItem);
                    },
                };
            }) || [],
        [topTokens],
    );



    const getTimestamps = () => {
        const formatDate = (date: any) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
        };
    
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        
        const threeDaysAgo = new Date();
        threeDaysAgo.setHours(threeDaysAgo.getHours() - 72);
     
        const oneMonthAgoTimestamp = Math.floor(oneMonthAgo.getTime() / 1000);
        const threeDaysAgoTimestamp = Math.floor(threeDaysAgo.getTime() / 1000);
    
        return {
          currentTimestamp,
          oneMonthAgoTimestamp,
          threeDaysAgoTimestamp,
          currentDate: formatDate(new Date()),
          oneMonthAgoDate: formatDate(oneMonthAgo),
          threeDaysAgoDate: formatDate(threeDaysAgo),
        };
      };

    useEffect(() => {
        if (listItems.length > 0) {
            setSelectedCoin(listItems[0]);
        }
        fetchTopTokens();
    }, [viewMode]);


    const fetchTopTokens = async () => {
        setLoading(true);
        setError(null);

        const { currentDate, oneMonthAgoDate, threeDaysAgoDate } = getTimestamps();

        const topTokensEndpoint = process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-tokens?interval=daily&videoLimit=50`
            : `${process.env.REACT_APP_AWS_API}/api/top-tokens?interval=daily&videoLimit=50`;

        // This is just looking for the last 24 hours
        const recentTokensEndpoint = process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/top-new-tokens?interval=daily&start_date=${threeDaysAgoDate}&end_date=${currentDate}`
            : `${process.env.REACT_APP_AWS_API}/api/top-new-tokens?interval=daily&start_date=${threeDaysAgoDate}&end_date=${currentDate}`;

        const emergingTokensEndpoint = process.env.REACT_APP_USE_REMOTE_API === 'true'
            ? `${process.env.REACT_APP_REMOTE_API}:${process.env.REACT_APP_SERVER_PORT}/api/emerging-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}`
            : `${process.env.REACT_APP_AWS_API}/api/emerging-tokens?interval=daily&start_date=${oneMonthAgoDate}&end_date=${currentDate}`;


        try {
            const endpoint = (() => {
                switch (viewMode) {
                    case 'top':
                        return topTokensEndpoint;
                    case 'recent':
                        return recentTokensEndpoint;
                    case 'emerging':
                        return emergingTokensEndpoint;
                    default:
                        return 'top-tokens';
                }
            })();

            const response = await fetch(endpoint);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setTopTokens(data);
            console.log("top default - get data");
            
            
            console.log("data", data);

            if (data.length > 0) {
                setSelectedCoin(createListItem(data[0]));
                console.log("top default - get selected coin");
            }
        } catch (error) {
            console.error('Error fetching tokens:', error);
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError(String(error));
            }
            console.log("top default - get selected coin");
        } finally {
            setLoading(false);
        }
    };

    const renderListItem = (item: ListProps, index: number) => (
        <List
            key={index}
            {...item}
            logo={item.logo ? item.logo : noLogoPath}
            secondTitle={item.secondTitle?.toUpperCase()}
            stats={item.stats.slice(0, 2)}
            spark={item.coords ? item.coords.map((coord) => coord.y) : []}
            selected={!!(selectedCoin && item.title === selectedCoin.title)}
        />

    );

    console.log("top default");

    const getTitle = () => {
        switch (viewMode) {
            case 'top':
                return "TOP COIN VIEWS";
            case 'recent':
                return "RECENTLY DISCUSSED COINS";
            case 'emerging':
                return "EMERGING TOKENS";
            default:
                return "TOP COIN VIEWS";
        }
    };

    const toggleButton = (


        <>
            <button
                className={`toggle-button-${viewMode === 'top' ? 'active' : 'inactive'}`}
                onClick={() => setViewMode('top')}
            >
                TOP COIN VIEWS
                {viewMode === 'top' && <i className="fa-sharp fa-solid fa-caret-down"></i>}
            </button>
            <button
                className={`toggle-button-${viewMode === 'recent' ? 'active' : 'inactive'}`}
                onClick={() => setViewMode('recent')}
            >
                RECENTLY DISCUSSED
                {viewMode === 'recent' && <i className="fa-sharp fa-solid fa-caret-down"></i>}

            </button>
            <button
                className={`toggle-button-${viewMode === 'emerging' ? 'active' : 'inactive'}`}
                onClick={() => setViewMode('emerging')}
            >
                EMERGING TOKENS
                {viewMode === 'emerging' && <i className="fa-sharp fa-solid fa-caret-down"></i>}
            </button>
        </>

    );



    return (
        <>
            <div className="general-container">

                <FeatureWithInteractiveList
                    loading={loading}
                    premium={premium}
                    title={getTitle()}
                    listItems={listItems}
                    selectedItem={selectedCoin}
                    setSelectedItem={setSelectedCoin}
                    FeaturedComponent={ChartDetailedInFull}
                    featuredStatName="Views Score"
                    featureBackground={viewMode === 'top'}
                    renderListItem={renderListItem}
                    toggleButton={toggleButton}
                />

            </div>

        </>

    );
}

export default React.memo(CoinsTopDefault);