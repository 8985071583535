import React from 'react';
import PrettyNumber from '../../components/PrettyNumber';
import NumberChange from '../../components/NumberChange';
import { ListProps } from '../../types/interfaces';

interface FeatureStatProps {
  subtitle: string;
  top?: string;
  listItem?: ListProps | null;
  value: number;
  mentions: number;
  valueChange?: number;
  disableMoreSuffix?: boolean;
  align?: 'left' | 'right';
}

const FeatureStat: React.FC<FeatureStatProps> = ({ subtitle, top, listItem, value, mentions, align, valueChange, disableMoreSuffix }) => {
  
  
  return (
    <>
      {value > 0 &&
        <>
          <div className="stat-right-hero">
            <span className="number">
              <PrettyNumber value={value} disableMoreSuffix={disableMoreSuffix} hero={true}/>
            </span>
            <small className="subtitle">{subtitle}</small>
          </div>
        </>
      }
      {!!valueChange &&
        <div className={valueChange && valueChange > 0 ? 'stat-right-hero-good' : 'stat-right-hero-bad'}>
          <span className="number">
            {(valueChange !== undefined && valueChange !== 0) && (
              <span className="number-change">
                <NumberChange value={valueChange} isArrow={true} />
              </span>
            )}
          </span>
          <small className="subtitle">CHANGE</small>
        </div>
      }
      
      {!!mentions &&
        <div className="stat-right-hero">
          <span className="number">
            <PrettyNumber value={mentions} disableMoreSuffix={disableMoreSuffix} hero={true} />
          </span>
          <small className="subtitle">MENTIONS</small>
        </div>
      }
    </>
  );
};

export default FeatureStat;
